exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-projects-casperblockchain-js": () => import("./../../../src/pages/projects/casperblockchain.js" /* webpackChunkName: "component---src-pages-projects-casperblockchain-js" */),
  "component---src-pages-projects-caspertigers-js": () => import("./../../../src/pages/projects/caspertigers.js" /* webpackChunkName: "component---src-pages-projects-caspertigers-js" */),
  "component---src-pages-projects-friendlysoccer-js": () => import("./../../../src/pages/projects/friendlysoccer.js" /* webpackChunkName: "component---src-pages-projects-friendlysoccer-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */)
}

